import _Reflect from "./Reflect.getPrototypeOf";
import _Object from "./Object.getPrototypeOf";
import * as _get2 from "dunder-proto/get";
var _get = _get2;
try {
  if ("default" in _get2) _get = _get2.default;
} catch (e) {}
var exports = {};
var reflectGetProto = _Reflect;
var originalGetProto = _Object;
var getDunderProto = _get;

/** @type {import('.')} */
exports = reflectGetProto ? function getProto(O) {
  // @ts-expect-error TS can't narrow inside a closure, for some reason
  return reflectGetProto(O);
} : originalGetProto ? function getProto(O) {
  if (!O || typeof O !== "object" && typeof O !== "function") {
    throw new TypeError("getProto: not an object");
  }
  // @ts-expect-error TS can't narrow inside a closure, for some reason
  return originalGetProto(O);
} : getDunderProto ? function getProto(O) {
  // @ts-expect-error TS can't narrow inside a closure, for some reason
  return getDunderProto(O);
} : null;
export default exports;